
import { computed, defineComponent, onMounted, onUpdated } from "vue";
import AddressesTE from "@/components/entities/tables-editable/AddressesTE.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { ElNotification } from "element-plus";

export default defineComponent({
  name: "ProfileOverview",
  components: {
    AddressesTE,
  },
  props: {
    profile: Object,
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const contactData = computed(() => store.getters.Contacts);
    const communicationData = computed(() => store.getters.Communications);
    const sectorData = computed(() => store.getters.Sectors);
    const addressData = computed(() => store.getters.Addresses);
    const officesData = computed(() => store.getters.Offices);
    const entityData = computed(() => store.getters.EntityData);
    const dataTypes = computed(() => store.getters.EntityTypes);
    const eDataTable = computed(() => store.getters.EconomicData);
    const sync = computed(() => store.getters.Sync);
    const createAddress = () => {
      let invalidTable = false;
      if (addressData.value.length > 0) {
        addressData.value.forEach((item, index) => {
          if (index === addressData.value.length - 1) {
            invalidTable = item.address_types === [] || item.street === "";
          }
        });
        if (!invalidTable) {
          store.commit("createAddresses", {
            id: 0,
            entity_id: route.params.id,
          });
        } else {
          store.commit("setLoadingStatus", false);
          ElNotification({
            title: "Error",
            message: t("raddresstable"),
            type: "error",
          });
        }
      } else {
        store.commit("createAddresses", {
          id: 0,
          entity_id: route.params.id,
        });
      }
    };
    const createCommunication = () => {
      store.commit("createCommunications", {
        id: undefined,
        name: "",
        description: "",
      });
    };
    const saveAll = () => {
      store.commit("setLoadingStatus", true);
      const entitiesModel = [] as any;
      dataTypes.value.forEach(function (value) {
        entitiesModel.push(value.id);
      });
      const addressModel = [] as any;
      let invalidTable = false;
      if (addressData.value.length > 0) {
        addressData.value.forEach((item, index) => {
          if (index === addressData.value.length - 1) {
            invalidTable = item.address_types === [] || item.street === "";
          }
        });
        if (!invalidTable) {
          addressData.value.forEach(function (value) {
            if (value.address_types[value.address_types.length - 1].id) {
              value.address_types = value.address_types.map((x) => x.id);
            }
            addressModel.push(value.id);
          });
        } else {
          store.commit("setLoadingStatus", false);
          ElNotification({
            title: "Error",
            message: t("raddresstable"),
            type: "error",
          });
        }
      }
      const contactsModel = [] as any;
      contactData.value.forEach(function (value) {
        contactsModel.push(value.id);
      });
      const officesModel = [] as any;
      officesData.value.forEach(function (value) {
        officesModel.push(value.id);
      });
      const sectorsModel = [] as any;
      sectorData.value.forEach(function (value) {
        sectorsModel.push(value.id);
      });
      const syncModel = [] as any;
      sync.value.forEach(function (value) {
        syncModel.push(value);
      });
      const economicData = [] as any;
      eDataTable.value.forEach(function (value) {
        economicData.push({
          type: value.type,
          office_id: value.office_id,
          payment_days: value.payment_days,
          applies: value.applies,
          payment_type_detail_id: value.payment_type_detail_id,
        });
      });
      const body = {
        name: entityData.value.name,
        legal_name: entityData.value.legal_name,
        identification_type_id: entityData.value.identification_type_id,
        identification: entityData.value.identification,
        email: entityData.value.email,
        entity_types: entitiesModel,
        sectors: sectorsModel,
        offices: officesModel,
        contacts: contactData.value,
        addresses: addressData.value,
        economic_data: economicData,
        synchronize_flags: syncModel,
        communications: communicationData.value,
      };

      if (!invalidTable) {
        ApiService.post(
          "/api/entities/updateAll/" + route.params.id,
          body
        ).then(function (response) {
          store.commit("setAddresses", response.data.addresses);
          store.commit("setLoadingStatus", false);
        });
      }
    };
    const removeAddress = (i) => {
      addressData.value.forEach(function (value, index) {
        if (index === i) {
          if (!value.id) {
            addressData.value.splice(i, 1);
          } else {
            if (index === i) {
              ApiService.delete(
                "/api/address/" + value.id + "?force_delete=false"
              ).then(function () {
                addressData.value.splice(i, 1);
              });
            }
          }
        }
      });
    };

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };

    const canUpdate = computed(() => {
      return (
          permissions.value.findIndex((x) => x.name === "entities: update") !==
          -1
      );
    });
    onUpdated(() => {
      console.clear();
    });

    onMounted(() => {
      console.clear();
    });
    return {
      saveAll,
      createAddress,
      removeAddress,
      createCommunication,
      canUpdate,
    };
  },
});
